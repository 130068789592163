import React from "react"

import Layout from "@/components/Layout"
import SEO from "@/components/SEO"
import WistiaPlayer from "@/components/WistiaPlayer"
import Accordion from "@/components/Accordion"
import { Link } from "gatsby"
import { routes } from "@/utils/routes"

type PageProps = {
  data: any
}

const SouthWingDevelopment: React.FC<PageProps> = ({ data }: { data: any }) => {
  return (
    <Layout
      sectionOne={
        <>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50 sm:hidden"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_consultation_banner_720.mp4" type="video/mp4" />
          </video>
          <video
            id="bgvid"
            className="absolute inset-0 w-full h-full object-cover -z-50  hidden sm:block"
            playsInline
            autoPlay
            muted
            loop
          >
            <source src="/videos/rbai_consultation_banner.mp4" type="video/mp4" />
          </video>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <div>
              <div className="pb-2 md:pb-12">
                <h1 className="responsive-heading uppercase">
                  The South Wing <span className="text-primary"> Development</span>
                </h1>
              </div>

              <div className="pt-8 flex justify-center">
                <img src="/images/down.png" alt="Down arrow" />
              </div>
            </div>
          </div>
        </>
      }
    >
      <SEO title="The South Wing Development" />

      <div className="pt-8 lg:pt-16">
        <div className="container px-2 lg:px-0">
          <div>
            <h2 className="leading-none text-3xl sm:text-5xl md:text-6xl uppercase">
              Creating a campus which will <span className="text-primary">benefit future generations</span>
            </h2>
          </div>
          <div className="grid gap-8 mt-16">
            <WistiaPlayer
              videoStyle={{
                width: "100%",
                height: "100%",
              }}
              videoID={"td233dbpbw"}
              className="aspect-video"
            />
            {/* <Link to={getVideoLink(0)}>
              <Challenge
                image={"/images/2023/03/videos/video-1-holding.jpg"}
                title={"Video Title"}
                description={"Video description goes here..."}
                href={null}
                wrapperClass="mb-3"
              />
            </Link> */}
          </div>
        </div>
      </div>

      <div className="container pt-14">
        <div className="flex justify-center">
          <div className="md:text-xl">
            <h3 className="mb-2 md:text-xl">A NEW SOUTH WING ON THE RBAI CAMPUS</h3>
            <p className="mb-4">RBAI has been educating boys in the heart of Belfast for over 200 years. </p>
            <p className="mb-4">The proposed South Wing will secure the School’s future on the site, providing the necessary infrastructure to support the educational needs of 1,100 pupils and staff who contribute to the vitality and vibrancy of the City Centre.</p>
            <p className="mb-4">Plans for the new South Wing include a multi-purpose Dining Hall, 15 general classrooms, a drama suite, a 25m swimming pool, a Board Room suite, living accommodation for the School Steward, a new small extension to the Soane Building and the development of landscaped zones.</p>
            <p className="mb-4">Facilitating the redevelopment of the site involves the demolition of existing school infrastructure that is not fit for purpose, including the dining hall, swimming pool and W-Block. </p>

            <h3 className="mt-8 mb-2 md:text-xl">WHY THIS DEVELOPMENT IS NEEDED</h3>
            <p className="mb-4">Teaching and learning styles are constantly evolving, the use of technology is advancing, along with the recognition that having a positive learning environment is critical to pupils’ mental health and wellbeing.</p>
            <p className="mb-4">RBAI is restricted by buildings which were designed for a very different era and an extensive review of the School’s estate highlighted:</p>
            <ul className="list-disc ml-4">
              <li className="mb-4">The classrooms in the 1920’s W Block no longer meet the Department of Education space standards and are not fit for purpose.</li>
              <li className="mb-4">With more than 12,000 individual usages each year, the Swimming Pool which was built in the 1960’s, and the 1950’s Dining Hall, serving over 700 boys daily, are no longer fit for purpose.</li>
              <li className="mb-4">COVID showed that high quality internal and external spaces are essential for the wellbeing of pupils and staff.</li>
              <li className="mb-4">Improvements to the surrounding area, including the new Transport Hub, offer opportunities for RBAI to better connect to the city.</li>
            </ul>

            <h3 className="mt-8 mb-2 md:text-xl">EQUIPPING PUPILS TO EXCEL</h3>
            <ul className="list-disc ml-4">
              <li className="mb-4">RBAI’s proposals will provide the necessary school infrastructure, with modern fit for purpose facilities, that support the educational needs, growth and development of 1,100 pupils and staff.</li>
              <li className="mb-4">Proposed plans will secure the future of RBAI as a centre of academic excellence, with high quality efficient buildings designed to be adaptable and flexible in meeting the needs of evolving curriculum requirements.</li>
              <li className="mb-4">The development of new landscaped zones will improve pupil and staff wellbeing.</li>
              <li className="mb-4">The planned development will enhance RBAI’s historic buildings and site, extending the role of the School as a thriving social hub in the city centre.</li>
              <li className="mb-4">Near Zero Carbon development will see energy inefficient buildings replaced with new infrastructure, significantly reducing the School’s carbon footprint.</li>
            </ul>
          </div>
        </div>
      </div>

      {/* <div className="container py-10">
        <Link to={routes.southWingFAQs()} className="link underline hover:no-underline">
          Read our Frequently Asked Questions
        </Link>
      </div> */}

      <div className="container py-10">
        <div className="grid md:grid-cols-3 gap-8">
          <a href="/docs/RBAI 1.pdf" target="_blank" className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div>
              <strong>View of Proposed Quad, New South Campus & Rear of Soane Building</strong>
            </div>
          </a>

          <a href="/docs/RBAI 2.pdf" target="_blank" className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div>
              <strong>Proposed Floor Plans</strong>
            </div>
          </a>

          <a href="/docs/RBAI 3.pdf" target="_blank" className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div>
              <strong>Proposed Building Elevations</strong>
            </div>
          </a>

          <a href="/docs/RBAI 4.pdf" target="_blank" className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div>
              <strong>Proposed South Wing Visuals</strong>
            </div>
          </a>

          <a href="/docs/RBAI 5.pdf" target="_blank" className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
            </svg>

            <div>
              <strong>Proposed Dining Hall</strong>
            </div>
          </a>

          <Link to={routes.southWingFAQs()} className="flex items-start justify-start p-4 rounded-md hover:bg-gray-100">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mr-4 flex-shrink-0">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z" />
            </svg>

            <div>
              <strong>Frequently Asked Questions</strong>
            </div>
          </Link>
        </div>
      </div>

    </Layout>
  )
}

export default SouthWingDevelopment
